import Api from "./Api.js";

export default {
  GetExpenseDetail(payload) {
    return Api().get(
      "/v2/emailexpense/" + payload.token + "?type=" + payload.type
    );
  },
  EmailExpenseAccept(payload, reason) {
    console.log("hululu");
    return Api().get(
      "/v2/emailexpense/accept/" + payload.token + "?reason=" + reason
    );
  },

  EmailExpenseReject(payload, reason) {
    return Api().get(
      "/v2/emailexpense/reject/" + payload.token + "?reason=" + reason
    );
  },
  EmailExpenseHold(payload, reason) {
    return Api().get(
      "/v2/emailexpense/hold/" + payload.token + "?reason=" + reason
    );
  },
  EmailExpenseUpdate(payload, reason) {
    return Api().put(
      "/v2/emailexpense/" + payload.token + "?reason=" + reason,
      payload.pData
    );
  },
  EmailExpenseResubmit(payload, reason) {
    return Api().put(
      "/v2/emailexpense/" + payload.token + "?reason=" + reason + "&resubmit=1",
      payload.pData
    );
  },
  getProjectMainCategories(payload, reason) {
    // http://localhost:5057/v2/emailexpense/getcategory/4/kozo61d53c38e9f74
    return Api().get(
      "/v2/emailexpense/getcategory/" + payload.project_id + "/" + payload.token
    );
  },
  getSubCategoriesByParentCategory(payload, reason) {
    // http://localhost:5057/v2/emailexpense/getcategory/4/kozo61d53c38e9f74
    return Api().get(
      "/v2/emailexpense/getcategory/" +
        payload.project_id +
        "/" +
        payload.token +
        "?category_id=" +
        payload.category_id
    );
  },
  getAllCountry(payload, reason) {
    // http://localhost:5057/v2/emailexpense/getcategory/4/kozo61d53c38e9f74
    return Api().get(
      "/v2/emailexpense/getcountry/" +
        payload.token +
        "?city=" +
        payload.city +
        "&country_id=" +
        payload.country_id
    );
  },
  getAllGST(payload, reason) {
    // http://localhost:5057/v2/emailexpense/getcategory/4/kozo61d53c38e9f74
    return Api().get("/v2/emailexpense/getgst/" + payload.token);
  },
};
