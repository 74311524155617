<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div v-if="!loading" style="margin-top: 30px">
    <h3 style="text-align: center; font-weight: bold">Expense Detail</h3>
    <table
      style="
        width: 100%;
        text-align: center;
        margin: 0px auto;
        max-width: 600px;
      "
    >
      <tbody v-if="type != 'edit' && type != 'resubmit'">
        <tr>
          <td style="color: #626262">
            <span>ProjectName</span>
          </td>
          <td style="padding-top: 10px"><span>Category</span></td>

          <td style="color: #626262"><span>Sub Category</span></td>
        </tr>
        <tr>
          <td style="font-size: 12px">
            <b>{{ pData.project_name }}</b>
          </td>
          <td style="font-size: 12px">
            <span
              ><b>{{ pData.category_name }}</b></span
            >
          </td>
          <td style="font-size: 12px">
            <span
              ><b>{{ pData.sub_category }}</b></span
            >
          </td>
        </tr>
        <!--2col-->
        <tr>
          <td style="padding-top: 10px"><span>VCHCode</span></td>
          <td style="color: #626262"><span>VendorName</span></td>

          <td style="padding-top: 10px"><span>Amount</span></td>
        </tr>
        <tr>
          <td style="font-size: 12px">
            <span
              ><b>{{ pData.VHC_code }}</b></span
            >
          </td>
          <td style="font-size: 12px">
            <span
              ><b>{{ pData.po_name }}</b></span
            >
          </td>
          <td style="font-size: 12px">
            <span
              ><b>{{ pData.total_amount }}</b></span
            >
          </td>
        </tr>
        <!--3col-->
        <tr>
          <td style="padding-top: 10px">CreatedBy</td>
          <td style="padding-top: 10px"><span>DateOfExpense</span></td>
          <td style="padding-top: 10px"><span>DueDate</span></td>
        </tr>
        <tr style="font-size: 12px">
          <td style="font-size: 12px">
            <b>{{ pData.user_name }}</b>
          </td>
          <td style="font-size: 12px">
            <span
              ><b>{{ pData.created_date }}</b></span
            >
          </td>
          <td style="font-size: 12px">
            <span
              ><b>{{ pData.payment_due_date || "-" }}</b></span
            >
          </td>
        </tr>
        <tr>
          <td style="padding-top: 10px">Invoice Number</td>
          <td style="padding-top: 10px"><span>Invoice Date</span></td>
          <td style="padding-top: 10px">Description</td>
        </tr>
        <tr style="font-size: 12px">
          <td style="font-size: 12px">
            <b>{{ pData.invoice_number || "-" }}</b>
          </td>
          <td style="font-size: 12px">
            <span
              ><b>{{ pData.invoice_date || "-" }}</b></span
            >
          </td>
          <td style="font-size: 12px">
            <span
              ><b>{{ pData.purchase_description }}</b></span
            >
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td style="color: #626262"><span>ProjectName</span></td>
          <td style="padding-top: 10px"><span>VendorName</span></td>
          <td style="padding-top: 10px"><span>VCHCode</span></td>
        </tr>
        <tr>
          <td style="font-size: 12px">
            <!-- <b>{{ pData.project_name }}</b> -->
            <vs-input
              type="text"
              name="Project Name"
              v-model="pData.project_name"
              class="w-full padme"
              placeholder=""
              :disabled="true"
            />
          </td>
          <td style="font-size: 12px">
            <span>
              <vs-input
                type="text"
                name="Vendor Name"
                v-model="pData.po_name"
                class="w-full padme"
                placeholder=""
                :disabled="true"
              />
            </span>
          </td>
          <td style="font-size: 12px">
            <span>
              <vs-input
                type="text"
                name="VCH Code"
                v-model="pData.VHC_code"
                class="w-full padme"
                placeholder=""
                :disabled="true"
              />
            </span>
          </td>
        </tr>
        <!--2col-->
        <tr>
          <td style="color: #626262"><span>Category</span></td>
          <td style="color: #626262"><span>Sub Category</span></td>

          <td style="padding-top: 10px"><span>Invoice Date</span></td>
        </tr>
        <tr>
          <td style="font-size: 12px">
            <span>
              <multiselect
                label="category_name"
                class="w-full"
                track-by="category_id"
                @select="onSuggestSelect"
                v-model="categoryValue"
                name="Category Name"
                :options="categoryList"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                placeholder="Select Option"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{
                  option.category_name
                }}</template>
              </multiselect>
            </span>
          </td>
          <td style="font-size: 12px">
            <span>
              <multiselect
                label="category_name"
                class="w-full"
                track-by="category_id"
                v-model="subcategoryValue"
                name="Subcategory Name"
                :options="subcategoryList"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                placeholder="Select Option"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{
                  option.category_name
                }}</template>
              </multiselect>
            </span>
          </td>
          <td style="font-size: 12px">
            <span>
              <vs-input
                type="date"
                name="Invoice Date"
                v-model="pData.invoice_date"
                class="w-full padme"
                placeholder=""
              />
            </span>
          </td>
        </tr>
        <!--3col-->
        <tr>
          <td style="padding-top: 10px">Country</td>
          <td style="padding-top: 10px"><span>Invoice Number</span></td>
          <td style="padding-top: 10px"><span>Payment Due Date</span></td>
        </tr>
        <tr style="font-size: 12px">
          <td style="font-size: 12px">
            <multiselect
              :options="countries"
              v-model="country_value"
              v-validate="'required'"
              track-by="country_id"
              label="country_name"
              placeholder="Country Name"
              name="Country Name"
              :searchable="true"
              :allow-empty="false"
              open-direction="bottom"
              deselect-label="Can't remove this value"
              @select="onSelectCountry($event)"
            >
              <template slot="singleLabel" slot-scope="{ option }">{{
                option.country_name
              }}</template>
            </multiselect>
          </td>

          <td style="font-size: 12px">
            <span>
              <vs-input
                type="text"
                name="PayMent Due date"
                v-model="pData.invoice_number"
                class="w-full padme"
                placeholder=""
              />
            </span>
          </td>
          <td style="font-size: 12px">
            <span>
              <vs-input
                type="date"
                name="Invoice Date"
                v-model="pData.payment_due_date"
                class="w-full padme"
                placeholder=""
              />
            </span>
          </td>
        </tr>
        <!--3col-->
        <tr>
          <td style="padding-top: 10px">Amount</td>
          <td style="padding-top: 10px"><span>Tax</span></td>
          <td style="padding-top: 10px"><span>GST Amount</span></td>
        </tr>
        <tr style="font-size: 12px">
          <td style="font-size: 12px">
            <vs-input
              type="text"
              name="PayMent Due date"
              v-model="pData.total"
              class="w-full padme"
              placeholder=""
              @change="CalcAmount"
            />
          </td>
          <td style="font-size: 12px">
            <multiselect
              label="gst_name"
              class="w-full"
              track-by="gst_id"
              v-model="gst_value"
              name="gst"
              @select="gstCalculate"
              :options="gst_list"
              :searchable="false"
              :allow-empty="false"
              open-direction="bottom"
              placeholder="Select Option"
              deselect-label="Can't remove this value"
            >
              <template slot="singleLabel" slot-scope="{ option }">{{
                option.gst_name
              }}</template>
            </multiselect>
          </td>
          <td style="font-size: 12px">
            <vs-input
              :disabled="gst_name != 'Manual Amount'"
              type="text"
              name="PayMent Due date"
              v-model="pData.gst_amount"
              class="w-full padme"
              placeholder=""
              value="0"
              disbaled="true"
              @change="CalcAmount"
            />
          </td>
        </tr>

        <!--4col-->
        <tr>
          <td style="padding-top: 10px"><span>Total</span></td>

          <td style="padding-top: 10px">CreatedBy</td>
          <td style="padding-top: 10px"><span>DateOfExpense</span></td>
        </tr>
        <tr style="font-size: 12px">
          <td style="font-size: 12px">
            <span>
              <vs-input
                type="text"
                name="PayMent Due date"
                v-model="pData.total_amount"
                class="w-full padme"
                placeholder=""
                disabled="true"
              />
            </span>
          </td>
          <td style="font-size: 12px">
            <vs-input
              type="text"
              name="PayMent Due date"
              v-model="pData.user_name"
              class="w-full padme"
              placeholder=""
              disabled="true"
            />
          </td>
          <td style="font-size: 12px">
            <vs-input
              type="text"
              name="PayMent Due date"
              v-model="pData.created_date"
              class="w-full padme"
              placeholder=""
              disabled="true"
            />
          </td>
        </tr>
        <tr v-if="type == 'resubmit'">
          <td colspan="3">Upload Document</td>
        </tr>
        <tr v-if="type == 'resubmit'">
          <td colspan="3">
            <vue-dropzone
              @vdropzone-removed-file="vremoved"
              @vdropzone-error-multiple="vdropzoneError"
              @vdropzone-complete-multiple="vdropzoneComplete"
              @vdropzone-added-files="vdropzoneFilesAdded"
              @vdropzone-success-multiple="vdropzoneSuccess"
              ref="myVueDropzoneFile"
              id="dropzone"
              name="dropzone"
              class="rounded"
              :options="dropzoneOptionsFiles"
            ></vue-dropzone>

            <vs-button
              v-if="fileUpload.deleteButtonStatus"
              type="filled"
              @click.prevent="clearFile()"
              class="mt-2 feather icon-trash"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="
        (type == 'reject' ||
          type == 'hold' ||
          type == 'resubmit' ||
          type == 'accept') &&
        Status &&
        Reply &&
        pMessage == ''
      "
      class="rebox"
    >
      <h5>{{ capitalizeFirstLetter(type) }} Reason</h5>
      <textarea v-model="reason"></textarea>
      <button @click.prevent="ActionExpense" class="btn-success btn">
        Submit
      </button>
    </div>
    <div v-if="type == 'edit' && Status" class="rebox1">
      <button
        @click.prevent="ActionExpense"
        class="btn-success btn"
        v-if="action == ''"
      >
        Update
      </button>
    </div>
    <!-- <div v-if="type == 'edit' && Status" class="rebox1">
      <button @click="ActionExpense" class="btn-success btn">Update</button>
    </div> -->
    <p :class="'basicfor ' + action">{{ pMessage }}</p>
    <!-- <p>{{ token }}-{{ type }}</p> -->
  </div>
  <div v-else>
    <p :class="'basicfor ' + action">{{ loadingMsg }}</p>
  </div>
</template>

<script>
import UserService from "@/services/EmailExpenseService.js";
import Input from "../forms/form-elements/input/Input.vue";
import { BASEURL } from "@/config/index";
import constant from "@/helper/constant.js";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  components: { Input, vueDropzone: vue2Dropzone },
  data() {
    return {
      uploadedDocument: "",
      uploadedDocumentArray: [],
      gst_value: null,
      gst_list: [],
      cities: [],
      city_value: null,
      countries: [],
      country_value: null,
      subcategoryValue: null,
      categoryValue: null,
      categoryList: [],
      Reply: true,
      reason: "",
      action: "",
      type: "",
      token: "",
      loading: true,
      loadingMsg: "Loading Data",
      pData: null,
      Status: true,
      project_id: 0,
      subcategoryList: [],
      gst_name: "",
      gst_percentage: 0,
      fileUpload: {
        file_url: "",
        deleteButtonStatus: false,
      },
      dropzoneOptionsFiles: {
        parallelUploads: 10,
        url: BASEURL() + "/v2/multipleUploads?organization=",
        ...constant.DEFAULT_FILES,
        acceptedFiles:
          ".pdf,.xls,.xlsx,.csv,.txt,.png,.jpg,.jpeg,.webp,.doc,.docx",
        headers: { "My-Awesome-Header": "header value" },
        uploadMultiple: true,
        maxFiles: 10,
        addRemoveLinks: true,
        //chunking: true,
      },
    };
  },
  computed: {},
  beforeMount() {},
  mounted() {
    this.$vs.loading();
    this.type = this.$route.params.type;
    this.token = this.$route.params.token;

    const payload = {
      token: this.token,
      type: this.type,
      pData: null,
    };
    UserService.GetExpenseDetail(payload)
      .then((response) => {
        const { data } = response;
        console.log(data.data, "data");
        this.$vs.loading.close();
        if (data && data.Status) {
          this.pData = data.data;
          this.pMessage = data.Message;
          this.loading = false;
          this.project_id = data.data.project_id;
          this.gst_percentage = data.data.gst_percentage;
          this.uploadedDocumentArray = data.data.purchase_image.split(",");
          // if (this.type == "accept") {
          //   this.acceptExpense();
          // } else {
          this.getAllCategoryListByProject(this.project_id);
          this.getCountryList();
          this.getCityList(this.pData.country_id);
          // }
        } else {
          this.Status = false;
          if (data.data) {
            this.pData = data.data;
            this.loading = false;
            this.pMessage = data.Message;
          }
          this.loadingMsg = data.Message;
        }
      })
      .catch((error) => {
        //alert();
        //alert(error.response.status);
        let expenseURL = window.location.href;

        let expUrlLength = expenseURL.split("?");

        if (expUrlLength.length == 1) {
          localStorage.setItem("targetUrl1", "");
          localStorage.setItem("tagetedUrl2", "");
          localStorage.setItem("tagetedUrl3", "");
          localStorage.setItem("tagetedUrl4", "");
        }

        //window.open(expenseURL, +"?d=1" + "_self");

        let tagetedUrl1 = expenseURL.split("?")[0] + "?d=1";
        let tagetedUrl2 = expenseURL.split("?")[0] + "?d=2";
        let tagetedUrl3 = expenseURL.split("?")[0] + "?d=3";
        let tagetedUrl4 = expenseURL.split("?")[0] + "?d=4";

        let url = localStorage.getItem("targetUrl1");
        let url2 = localStorage.getItem("tagetedUrl2");
        let url3 = localStorage.getItem("tagetedUrl3");
        let url4 = localStorage.getItem("tagetedUrl4");

        if (!url && tagetedUrl1 != url) {
          localStorage.setItem("targetUrl1", tagetedUrl1);
          location.href = tagetedUrl1;
        } else if (!url2 && tagetedUrl2 != url2) {
          localStorage.setItem("tagetedUrl2", tagetedUrl2);

          location.href = tagetedUrl2;
        } else if (!url3 && tagetedUrl3 != url3) {
          localStorage.setItem("tagetedUrl3", tagetedUrl3);
          location.href = tagetedUrl3;
        } else if (!url4 && tagetedUrl4 != url4) {
          localStorage.setItem("tagetedUrl4", tagetedUrl4);
          location.href = tagetedUrl4;
        }

        console.log(expenseURL, "error");
        this.$vs.loading.close();
        this.loadingMsg = "Fetching expense data please wait!";
      });
  },
  methods: {
    vdropzoneSuccess: function (file, response) {
      console.log("response :", response);
      // this.user_pic = response.data.image_path;
      // this.uploadedImage = response.data.image_path;
      this.uploadedDocumentArray.push(response.data.image_path);
      this.uploadedDocument = this.uploadedDocumentArray.toString();
      // console.log(this.uploadedDocument, " uploadedDocument");
      // this.imageUpload.deleteButtonStatus = true;
      // // this.$refs.myVueDropzone.disable();
      // this.file_upload = false;
      console.log(this.uploadedDocument, "this.uploadedDocument");
    },
    vremoved: function (file) {
      // console.log("file, xhr, error :", file.upload);
      let uploaded = this.uploadedDocument.split(",");
      var a = this.selectedFilesUUID.indexOf(file.upload.uuid);
      if (a > -1) {
        uploaded.splice(a, 1);
        this.selectedFilesUUID.splice(a, 1);
        this.uploadedDocumentArray.splice(a, 1);
      }
      this.uploadedDocument = uploaded.toString();
      if (uploaded.length <= 0) {
        //   this.$refs.myVueDropzoneFile.removeAllFiles();
        this.fileUpload.deleteButtonStatus = false;
        //   this.$refs.myVueDropzoneFile.enable();
        this.uploadedDocument = "-";
        this.uploadedDocumentArray = [];
        this.selectedFilesUUID = [];
      }
      console.log(
        this.selectedFilesUUID,
        "==",
        this.uploadedDocumentArray,
        "==",
        this.uploadedDocument
      );
      this.file_upload = true;
    },
    vdropzoneComplete: function (response) {
      console.log("response :", response);
    },
    vdropzoneError: function (file, message, xhr) {
      console.log("file, message, xhr :", file, message, xhr);
    },
    clearFile: function () {
      this.fileUpload.deleteButtonStatus = false;
      this.$refs.myVueDropzoneFile.enable();
      this.$refs.myVueDropzoneFile.removeAllFiles();
    },
    vdropzoneFilesAdded: function (file) {
      console.log("file 1 :", file);
    },
    CalcAmount: function () {
      let amount =
        this.pData.total > 0 ? parseFloat(this.pData.total).toFixed(2) : 0;
      if (this.pData.gst_amount <= 0 || isNaN(this.pData.gst_amount)) {
        this.pData.gst_amount = 0;
      }
      if (this.gst_percentage >= 0) {
        let totalAmount =
          (parseFloat(this.gst_percentage).toFixed(2) * amount) / 100;
        if (isNaN(totalAmount)) {
          totalAmount = 0;
        }
        this.pData.gst_amount = parseFloat(totalAmount).toFixed(2);
      }
      this.totalAmount();
    },
    gstCalculate: function (selected) {
      this.gst_name = selected.gst_name;
      this.gst_percentage = selected.gst_percentage;
      this.pData.gst_amount = 0;

      this.CalcAmount();
    },
    totalAmount() {
      let amount =
        this.pData.total != "" ? parseFloat(this.pData.total).toFixed(2) : 0;

      this.pData.total_amount =
        (this.pData.gst_amount ? parseFloat(this.pData.gst_amount) : 0) +
        parseFloat(amount);
    },
    onSuggestSelect: function (selected) {
      this.subcategoryValue = null;
      this.pData.category_id = 0;
      this.getSubcategoryList(selected.category_id, this.project_id);
    },
    getSubcategoryList: function (category_id, project_id) {
      const payload = {
        token: this.token,
        project_id: project_id,
        category_id: category_id,
      };
      console.log(payload, "payload");
      UserService.getSubCategoriesByParentCategory(payload)
        .then((response) => {
          const { data } = response;
          if (data["data"] && data.Status) {
            this.subcategoryList = data.data;
            this.subcategoryList.map((x) => {
              if (this.pData.category_id === x.category_id) {
                this.subcategoryValue = x;
                // this.form.category_id = x.category_id;
              }
            });
          } else {
            this.$vs.notify({
              title: "Error!",
              text: "No Data Found",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.$vs.notify({
            title: "Error!",
            text: "No Data Found",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    onSelectCountry({ country_id }) {
      this.getCityList(country_id);
    },
    getCountryList: function () {
      const payload = {
        token: this.token,
        project_id: this.roject_id,
      };
      UserService.getAllCountry(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.countries = data.data;
            this.countries.map((x) => {
              if (this.pData.country_id == x.country_id) {
                this.country_value = x;
              }
            });
          } else {
            // TODO Show Error Message, Whcih is comming from backend
          }
        })
        .catch((error) => {});
    },
    getCityList: function (id = 0) {
      this.cities = [];
      this.city_value = null;
      this.city_id = null;
      const payload = {
        token: this.token,
        project_id: this.roject_id,
        country_id: id == 0 ? this.pData.country_id : id,
        city: 1,
      };
      console.log(this.cities, "this.cities");

      UserService.getAllCountry(payload)
        .then((response) => {
          const { data } = response;
          console.log(data, "cities");
          if (data.Status) {
            this.cities = data.data;

            console.log(data.data, "this.cities");
            this.cities.map((x) => {
              if (this.pData.city_id == x.city_id) {
                this.city_value = x;
              }
            });
          } else {
            // TODO Show Error Message, Whcih is comming from backend
          }
        })
        .catch((error) => {});

      UserService.getAllGST(payload)
        .then((response) => {
          const { data } = response;
          console.log(data, "cities");
          if (data.Status) {
            this.gst_list = data.data;

            console.log(this.pData.gst_id, "this.this.pData.gst_id");
            this.gst_list.map((x) => {
              if (this.pData.gst_id == x.gst_id) {
                this.gst_value = x;
                this.gst_name = x.gst_name;
              }
            });
          } else {
            // TODO Show Error Message, Whcih is comming from backend
          }
        })
        .catch((error) => {});
    },
    getAllCategoryListByProject: function (id) {
      const payload = {
        token: this.token,
        project_id: id,
      };
      UserService.getProjectMainCategories(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.categoryList = data.data;

            this.categoryList.map((x) => {
              if (this.pData.parent_id === x.category_id) {
                this.categoryValue = x;
                // this.form.parent_id = x.category_id;
                this.getSubcategoryList(x.category_id, this.project_id);
              }
            });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    ActionExpense() {
      let that = this;
      try {
        if (
          !this.pData.total_amount ||
          !this.categoryValue ||
          !this.subcategoryValue ||
          !this.pData.total ||
          !this.pData
        ) {
          this.$vs.notify({
            title: "Fill mandatory fields!",
            text: "Mandatory fields missing.",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
          return;
        }

        console.log(this.gst_value);

        let payload = {
          token: this.token,
          type: this.type,
          pData: null,
        };

        if (this.type == "reject") {
          if (!this.reason) {
            this.$vs.notify({
              title: "Reason Missing",
              text: "Please provide the reason for rejection.",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
            return;
          }
          UserService.EmailExpenseReject(payload, this.reason)
            .then((response) => {
              const { data } = response;
              this.$vs.loading.close();
              if (data && data.Status) {
                that.Reply = false;
                that.pMessage = data.Message;
                that.action = data.flag;
                // console.log(that.pMessage, that.action);
              } else {
                that.action = data.flag;
                that.pMessage = data.Message;
              }
            })
            .catch((error) => {
              console.log(error, "error");
              that.$vs.loading.close();
              that.loadingMsg = "Something went wrong contact Admin!";
            });
        } else if (this.type == "accept") {
          if (!this.reason) {
            this.$vs.notify({
              title: "Reason Missing",
              text: "Please provide the reason for Acceptance.",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
            return;
          }
          UserService.EmailExpenseAccept(payload, this.reason)
            .then((response) => {
              console.log("830 reached here");
              const { data } = response;
              this.$vs.loading.close();
              if (data && data.Status) {
                that.Reply = false;
                that.pMessage = data.Message;
                that.action = data.flag;
                // console.log(that.pMessage, that.action);
              } else {
                that.action = data.flag;
                that.pMessage = data.Message;
              }
            })
            .catch((error) => {
              console.log(error, "error");
              that.$vs.loading.close();
              that.loadingMsg = "Something went wrong contact Admin!";
            });
        } else if (this.type == "hold") {
          if (!this.reason) {
            this.$vs.notify({
              title: "Reason Missing",
              text: "Please provide the reason for hold.",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
            return;
          }
          UserService.EmailExpenseHold(payload, this.reason)
            .then((response) => {
              const { data } = response;
              this.$vs.loading.close();
              if (data && data.Status) {
                console.log(data, "EmailExpenseAccept");
                that.pMessage = data.Message;
                that.action = data.flag;
                // console.log(that.pMessage, that.action);
              } else {
                that.action = data.flag;
                that.pMessage = data.Message;
              }
            })
            .catch((error) => {
              console.log(error, "error");
              that.$vs.loading.close();
              that.loadingMsg = "Something went wrong contact Admin!";
            });
        } else if (this.type == "edit") {
          payload.pData = {
            total: this.pData.total,
            gst_amount: this.pData.gst_amount ? this.pData.gst_amount : 0,
            gst_id: this.gst_value ? this.gst_value.gst_id : 0,
            total_amount: this.pData.total_amount,
            invoice_number: this.pData.invoice_number,
            invoice_date: this.pData.invoice_date,
            payment_due_date: this.pData.payment_due_date,
            country_id: this.country_value.country_id,
            city_id: this.city_value.city_id,
            category_id: this.subcategoryValue.category_id,
          };
          UserService.EmailExpenseUpdate(payload, this.reason)
            .then((response) => {
              const { data } = response;
              this.$vs.loading.close();
              if (data && data.Status) {
                console.log(data, "EmailExpenseAccept");
                that.pMessage = data.Message;
                that.action = data.flag;
                // console.log(that.pMessage, that.action);
              } else {
                that.action = data.flag;
                that.pMessage = data.Message;
              }
            })
            .catch((error) => {
              console.log(error, "error");
              that.$vs.loading.close();
              that.loadingMsg = "Something went wrong contact Admin!";
            });
        } else if (this.type == "resubmit") {
          if (!this.reason) {
            this.$vs.notify({
              title: "Reason Missing",
              text: "Please provide the reason for re-submission!",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
            return;
          }
          // console.log(this.subcategoryValue, "subcategoryValue");
          if (this.uploadedDocument == "") {
            this.uploadedDocument = this.uploadedDocumentArray.toString();
          }

          payload.pData = {
            total: this.pData.total,
            gst_amount: this.pData.gst_amount,
            gst_id: this.gst_value ? this.gst_value.gst_id : 0,
            total_amount: this.pData.total_amount,
            invoice_number: this.pData.invoice_number,
            invoice_date: this.pData.invoice_date,
            country_id: this.country_value.country_id,
            city_id: this.city_value.city_id,
            category_id: this.subcategoryValue.category_id,
            purchase_image: this.uploadedDocument,
            token: this.token,
            type: this.type,
          };

          UserService.EmailExpenseResubmit(payload, this.reason)
            .then((response) => {
              const { data } = response;
              this.$vs.loading.close();
              if (data && data.Status) {
                that.pMessage = data.Message;
                that.action = data.flag;
                that.Status = data.Status;
                // console.log(that.pMessage, that.action);
              } else {
                that.action = data.flag;
                that.pMessage = data.Message;
              }
            })
            .catch((error) => {
              console.log(error, "error");
              that.$vs.loading.close();
              that.loadingMsg = "Something went wrong contact Admin!";
            });
        }
      } catch (error) {
        that.$vs.loading.close();
        that.loadingMsg = "Something went wrong contact Admin!";
      }
    },
    acceptExpense() {
      this.$vs.loading();
      let that = this;
      // that.pMessage = "---";
      const payload = {
        token: that.token,
        type: that.type,
        pData: null,
      };
      UserService.EmailExpenseAccept(payload, that.reason)
        .then((response) => {
          const { data } = response;
          this.$vs.loading.close();
          if (data && data.Status) {
            console.log(data, "EmailExpenseAccept");
            that.pMessage = data.Message;
            that.action = data.flag;
            // console.log(that.pMessage, that.action);
          } else {
            that.action = data.flag;
            that.pMessage = data.Message;
          }
        })
        .catch((error) => {
          console.log(error, "error");
          that.$vs.loading.close();
          that.loadingMsg = "Something went wrong contact Admin!";
        });
    },
  },
};
</script>

<style lang="scss">
.padme {
  padding: 10px;
}
.rebox1 {
  margin-top: 30px;
  text-align: center;
}
.rebox {
  width: 400px;
  margin: 0 auto;
  margin-top: 30px;
}

.rebox textarea {
  width: 100%;
  height: 100px;
}
.warning {
  color: orange;
}

.error {
  color: red;
}
.basicfor {
  margin: 0 auto;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  margin-top: 30px;
}

.success {
  color: green;
}
#page-login {
  .social-login {
    .bg-facebook {
      background-color: #1551b1;
    }
    .bg-twitter {
      background-color: #00aaff;
    }
    .bg-google {
      background-color: #4285f4;
    }
    .bg-github {
      background-color: #333;
    }
  }
}
</style>
